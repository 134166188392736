import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import Home from "./page/Home";
import Home from "./page/Home2";
import Start from "./page/Start";
import Ticket from "./page/Ticket";
import Timeline from "./page/Timeline";
import Post from "./page/Post";
import Post2 from "./page/Post2";
import Login from "./page/Login";
import Register from "./page/Register";
// import Index from "./page";
import Index from "./page/Index2";
// import HomeBl from "./page/Home";
import Itinerary from "./page/Itinerary";
// import Index from "./page/Index3";
import HomeWithProvider from './page/Home2Wrapper';

// import socketIO from 'socket.io-client';
// const socket = socketIO.connect('http://localhost:4000');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
        <Router>
          <Routes>
            <Route exact path="/" element={<Index/>}/>
            <Route exact path="/login" element={<Login/>}/>
            <Route exact path="/start" element={<Start/>}/>
            {/* <Route exact path="/home" element={<Home/>}/> */}
            <Route exact path="/home" element={<HomeWithProvider />}/>
            <Route exact path="/ticket" element={<Ticket/>}/>
            <Route exact path="/timeline" element={<Timeline/>}/>
            <Route exact path="/post" element={<Post/>}/>
            <Route exact path="/post2" element={<Post2/>}/>
            <Route exact path="/register" element={<Register/>}/>
            {/*<Route exact path="/homebl" element={<HomeBl/>}/>*/}
            <Route exact path="/itinerary" element={<Itinerary/>}/>
          </Routes>
        </Router>
    );
  }
}
export default App;
