import React from 'react';
import {
    Container, Navbar, NavbarBrand, Input, Card, Row, Col,
    Collapse, Nav, NavItem, NavLink, FormGroup, Button, Label

} from 'reactstrap';

import { GrFormClose } from 'react-icons/gr';
import ProfileImg from '../img/profile.png';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Logo from "../img/logo_new.png";
import IconSearch from "../img/icon/icon-search.png";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.openSearch = this.openSearch.bind(this);

    }


    componentDidMount() {

    }

    openSearch(){
        var element2 = document.getElementById("search-bar");
        if(element2.classList.contains("d-none") === false){
            element2.classList.add("d-none");
        }
        else{
            element2.classList.remove("d-none");
        }
    }


    render() {
        return (
            <div className="bg">
               <Navbar id="navFridayis" className="container bg-transparent fixed-top">
                        <div className="w-50 ">
                                <NavbarBrand role="button" href="/"><img src={Logo} className="logo-img" alt="Profile" /><p className="d-none d-md-inline-flex fw-bold mx-3" style={{"fontSize": "16px","paddingTop":"1rem"}}>Fridayis</p></NavbarBrand>
                            {/*<div className="d-none d-md-inline-flex fw-bold pt-point75">Fridayis</div>*/}
                                <p className="fw-bold title-nav pt-point75">{this.state.titleNav}</p>
                            </div>
                        <div className="d-flex mx-4">
                                <button className="sarch-btn" onClick={this.openSearch}><img src={IconSearch} className="nav-icon" alt="icon search"/></button>
                                <div className="mt-1">
                                    {(localStorage.getItem("imageUrl")) ? <NavbarBrand role="button" onClick={this.toggleMobile}><img src={localStorage.getItem("imageUrl")} className="profile-img" alt="Profile" /></NavbarBrand> : <NavbarBrand role="button" onClick={this.toggleMobile}><img src={ProfileImg} className="profile-img" alt="Profile" /></NavbarBrand>}
                                </div>
                        </div>
                    </Navbar>


            </div>
        );
    }
}
export default Home